<template>
  <div class="SalaryQuery">
    <div class="Query_h">
      <div class="Query_bac">
        <div class="Query_til">
          <img src="@/assets/img/query/query_til.png" alt="">
        </div>
      </div>
      <div class="Query_box">
        <div class="Query_group" v-for="(group, index) in list" :key="index">
          <div class="Query_group_til">{{ group.groupName }}</div>
          <div class="Query_group_options">
            <div class="Query_cell"
              v-for="item in group.groupOptions"
              :key="item.name"
              @click="toQuery(item)"
              v-click-call-log="{ clickPlace: '按钮-'+item.name, queryResult: 'click success' }"
            >
              <div class="icon_wrap">
                <img class="icon" :src="item.icon" alt="">
              </div>
              <h4 class="title">{{item.name}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <copyrightIp></copyrightIp>
  </div>
</template>

<script>
import { oauthLogin } from "@/api/PeopleCenter";
import { getQueryFuctions } from "@/api/salaryQuery";
import { Button, Notify } from "vant";
import copyrightIp from "@/components/copyrightIp";
import logDirective from './js/logDirective.js';
export default {
  components: {
    [Button.name]: Button,
    copyrightIp,
  },
  data() {
    return {
      list: [
        {
          groupName: '涉税类',
          groupOptions: [
            {
              type: 'corporatePension',
              name: '企业年金',
              icon: require('../../assets/img/query/icon_corporatePension.png'),
              codeItemId: '93020002'
            },
            {
              type: 'dimissionAllowance',
              name: '经济补偿',
              icon: require('../../assets/img/query/icon_dimissionAllowance.png'),
              codeItemId: '93020003'
            },
            {
              type: 'housingFund',
              name: '住房公积金',
              icon: require('../../assets/img/query/icon_housingFund.png'),
              codeItemId: '93020004'
            },
            {
              type: 'disabledExemption',
              name: '残疾人免税',
              icon: require('../../assets/img/query/icon_disabledExemption.png'),
              codeItemId: '93020012'
            },
            {
              type: 'continuingEducation',
              name: '继续教育',
              icon: require('../../assets/img/query/icon_continuingEducation.png'),
              codeItemId: '93020013'
            },
            {
              type: 'housingRent',
              name: '住房租金',
              icon: require('../../assets/img/query/icon_housingRent.png'),
              codeItemId: '93020011'
            }
          ]
        },
        {
          groupName: '薪资类',
          groupOptions: [
            {
              type: 'averageSalary',
              name: '平均工资',
              icon: require('../../assets/img/query/icon_averageSalary.png'),
              codeItemId: '93020001'
            },
            {
              type: 'minimumWage',
              name: '最低工资',
              icon: require('../../assets/img/query/icon_minimumWage.png'),
              codeItemId: '93020005'
            },
            {
                type: 'socialSecurityPayment',
                name: '社保缴纳',
                icon: require('../../assets/img/query/icon_socialSecurityPayment.png'),
                codeItemId: '93020014'
            }
          ]
        },
        {
          groupName: '假期类',
          groupOptions: [
            {
              type: 'maternityLeave',
              name: '婚假产假',
              icon: require('../../assets/img/query/icon_maternityLeave.png'),
              codeItemId: '93020006'
            },
            {
              type: 'childCare',
              name: '子女护理假',
              icon: require('../../assets/img/query/icon_childCare.png'),
              codeItemId: '93020010'
            }
          ]
        },
        {
          groupName: '津贴类',
          groupOptions: [
            {
              type: 'highTemperatureAllowance',
              name: '高温津贴',
              icon: require('../../assets/img/query/icon_highTemperatureAllowance.png'),
              codeItemId: '93020007'
            },
            {
              type: 'transportationCommunication',
              name: '交通通讯补贴',
              icon: require('../../assets/img/query/icon_transportationCommunication.png'),
              codeItemId: '93020008'
            }
          ]
        },
        {
          groupName: '政策类',
          groupOptions: [
            {
                type: 'welfare',
                name: '优惠政策',
                icon: require('../../assets/img/query/icon_welfare.png'),
                codeItemId: '93020015'
            }
          ]
        }
      ]
    }
  },
  created() {
     // localStorage.setItem( 'querSalaryUserId' , Math.random().toString(36).substring(2, 10));
    sessionStorage.removeItem('selected');
    const code = this.getUrlParam("code"); //是否存在wx code
    if (code) {
      oauthLogin({ code }).then((res) => {
        let openId = res.data.data.openid;
        localStorage.setItem("querSalaryUserId", openId);
      });
    } else {
      // this.authWxOpenId()
    }

    this.fetchQueryTypeList()
  },
  methods: {
    // 获取微信openId
    authWxOpenId() {
      if (localStorage.getItem('querSalaryUserId')) {
        return
      }
      let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
      let local = encodeURIComponent(window.location.href); //获取当前url
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect`;
    },
    // 获取url地址栏参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var rrr = decodeURIComponent(window.location.search);
      var r = rrr.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    fetchQueryTypeList() {
      getQueryFuctions().then(res => {
        if (res.data.code == 0) {
          this.mergeListWithResList(res.data.data)
        } else {
          Notify("获取查询字典失败!");
        }
      })
    },
    mergeListWithResList(resList) {
      this.list = this.list.map(item1 => {
        const correspondingItem = resList.find(item2 => item1.name === item2.codeItemName)
        return { ...item1, ...correspondingItem }
      })
    },
    toQuery(item) {
      if (localStorage.getItem('querSalaryUserId'))  {
        if (item.codeItemId == '93020013') {
          this.$router.push(`/salaryQueryv1/certificate/${item.codeItemId}/${item.type}`);
        } else if (item.codeItemId == '93020015') {
            this.$router.push(`/salaryQueryv1/policy/${item.codeItemId}/${item.type}`);
        } else {
          this.$router.push(`/salaryQueryv1/region/${item.codeItemId}/${item.type}`);
        }
      } else {
        this.authWxOpenId()
      }
    }
  },
  directives: {
    clickCallLog: logDirective
  }
}
</script>

<style lang="scss" scoped>
.SalaryQuery {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: auto;
  .Query_h {
    min-height: calc(100% - 44px);
  }
  .Query_bac {
    width: 100%;
    height: 113px;
    background-image: url("../../assets/img/query/bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .Query_til {
    width: 300px;
    padding: 32px 0;
    margin: auto;
    img{
      text-align: center;
      width: 100%;
    }
  }
  .Query_box {
    padding: 0 15px;
    .Query_group {
      margin-top: 3px;
    }
    .Query_group_til {
      font-size: 15px;
      font-weight: bold;
      padding: 5px 15px;
      background-color: #f2f3f5;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
    }
    .Query_group_options {
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      .Query_cell {
        display: inline-block;
        width: 25%;
        padding: 15px 0;
        text-align: center;
        .icon_wrap {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 55px;
          background-color: rgba(0, 127, 255, 0.05);
          border-radius: 10px;
      
        }
        .icon {
          width: 40px;
          height: 40px;
        }
        .title {
          text-align: center;
          margin-top: 9px;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          color: #333;
        }
      }
    }
  }
  .copyright {
    margin-top: 70px;
    /*padding-bottom: 30px;*/
  }
}
</style>