import { postLog } from "@/api/salaryQuery";

export default {
  inserted(el, binding) {
    const apiParams = binding.value;

    el.addEventListener("click", async function () {
      try {
        const reqData = {
          ...apiParams,
          userId: localStorage.getItem('querSalaryUserId'),
        };
        await postLog(reqData);
      } catch (error) {}
    });
  },
};
